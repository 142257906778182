// Homepage Styles

$ilq-red : #EA3525;
$ilq-red-dark: #D22F21;

$ilq-green: #ECFF00;

.home-body{
    padding-top: 56px;
    background: #6FCDCE;
    width: 100vw;
    background-image: url('../img/homepage/bg-blue.png');
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: 'Jost', Verdana, sans-serif;   

    .section-title{
        text-align: center;
        color: #4B4B4B;
        font-size: 2em;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.5em;
        
        @media (min-width: 768px){
            font-size: 3em;
        }
    }

}

.home-header{
    background: $ilq-red;
    color: #FFFFFF;

    .logo{
        width: 250px;
        border-radius: 5px;
    }

    a.nav-link{
        font-family: 'Jost', Verdana, sans-serif;
        font-size: 12px;
        text-transform: uppercase;
        color: #FFFFFF !important;
        letter-spacing: 1px;
    }

    .btn {
        font-family: Verdana, Helvetica, sans-serif;
        color: #4B4B4B; 
        font-size: 12px;
        border: 2px solid #FFFFFF;
        border-radius: 6px;
        
        &.btn-login{
            color: #EA3525;
            background: #FFF;
        }

        &.btn-register{
            color: #EA3525;
            background: #FFF;
            box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.35);
            font-weight: bold;
        }
    }


}

.header-zigzag{
    background-image: url('../img/homepage/zigzag-red.png');
    position: absolute;
    height: 45px;
    bottom: -17px;
    width: 100vw;
    left: 0px;
    background-size: auto 45px;
    z-index: -1;
}

.header-bottom{
        height: 30px;
        width: 100%;
        margin-top: -20px;
        background-size: contain;
        background-repeat: round;
        background-image: url('../img/homepage/zigzag-red.png');
}

.background-blue{
    width: 100vw;
    background-size: contain;
    background-position: top;
    background-image: url('../img/homepage/bg-blue.png');
}

.home-hero{
    font-family: 'Jost', Verdana, sans-serif;
    
    .btn-primary-hero{
        background-color: $ilq-red;
        border-color: $ilq-red;
        font-size: 20px;
        color: #FFFFFF;
        transition-delay:0s;
        transition-duration:0.3s;
        transition-property:all;
        transition-timing-function:ease;
        margin-right: 1em;

        &:visited, &:active{
            background-color: $ilq-red-dark;
            border-color: $ilq-red-dark;
            color: #FFFFFF;    
        }

        &:hover{
            transform: scale(1.2);
            background-color: $ilq-green;
            border-color: $ilq-green;
            color: #191919; 
        }
    }

    .btn-outline-primary-hero{
        border-color: $ilq-red;
        color: $ilq-red;
        font-size: 20px;

        &:hover{
            background-color: $ilq-red;
            border-color: $ilq-red;
            color: #FFFFFF;
        }
    }

    &.top{
        margin-top: 130px;
        margin-bottom: 3em;
        background: rgba(255,255,255,0.9);
        border-radius: 24px;
        max-width: 1000px;

        .row.first{
            margin-bottom: 10px;

            .left{
                font-size: 2.3em;
                font-family: 'Jost', sans-serif;
                padding: 1.5em 1em;
                font-weight: bold;
                z-index: 10;
            }

            .right{
                text-align: right;
                img {
                    margin-top: -27%;
                    max-width: 130%;
                    height: 560px;
                    margin-left: -6em;
                }

                @media (max-width: 991px) {

                    img{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: -3em;
                        width: 100%;
                        margin-left: unset;
                    }
                }
            }    
        }

        .row.second{

            #video-content {
                display: none;

                iframe{
                    border-radius: 10px;
                    max-width: 100%;
                }

                .close{
                    font-size: 1em;
                    font-weight: normal;
                    color: red;
                    padding-top: 1em;
                }
            }

            .video-cover{
                padding-bottom: 1em;
                position: relative;

                .cover{
                    
                }
                
                .play-icon{
                    position: absolute;
                    bottom: 15%;
                    right: 30%;
                    cursor: pointer;
                    transition: all 0.5s ease-out;

                    &:hover{
                        transform: scale(1.1);
                    }
                }
            }

            .explainer{
                font-size: 12px;
                color: #4B4B4B;
                font-size: 1.2em;
                padding: 1em;
                padding-top: 2em;

            }

            .rocket{
                position: absolute;
                top: 5px;
                right: -10%;
                opacity: 0.5;
            }

            .soft-line{
                div{
                    margin: 0 1em;
                    border-top: 1px solid #F6F6F6;
                    margin-top: -10px;
                }
            }
        }
        
    }
}

.home-info{
    padding-bottom: 3em;

    .info{
        display: flex;
        align-items: stretch;

        .info-content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start; 

            @media (max-width: 991px) {
                img{
                    width: 30%;
                }
            }

            .holder{
                background: #FFFFFF;
                padding: 1em;
                border-radius: 10px;
                margin-top: -50px;
                padding-top: 60px;
                text-align: center;
                z-index: -1;
                flex-grow: 1;

                .title{
                    font-size: 2em;
                    color: #EA3525;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

//safe
.home-feedbacks{
    background: #FFFFFF;
    padding-bottom: 30px;
    padding-top: 30px;

    .holder{
        background: #EA3525;
        padding: 2em;
        display: flex;
        position: relative;
        height: 300px;
        border-radius: 10px;
        align-items: center;
        overflow: hidden;
        margin-bottom: 15px;

        .feedback{
            font-size: 1.2em;
            text-align: center;
            z-index: 10;
            color: #FFFFFF;
        }

        .moon{
            position: absolute;
            bottom: -4em;
        }

        .heart{
            position: absolute;
            right: -30%;
        }

    }
}

.home-topics {
    background-image: url('../img/homepage/bg-green-bright.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #FFFFFF;
    margin-top: -6em;
    padding-top: 7em;
    padding-bottom: 2em;

    .topic-kids{
        border-radius: 50%;
        border: 10px solid white;
        overflow: hidden;
        background-color: #FFFFFF;
        margin: 2em;
    }

    .info-group{
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;  

        .title{
            font-weight: bold;
            font-size: 1.5em;
            background: red;
            color: #FFFFFF;
            padding: 3px 7px;
            border-radius: 10px;
            margin-bottom: 5px;
        }

        .info{
            color: #4B4B4B;
        }
    }

}

.home-awards {
    background: #FFFFFF;
    padding: 1em;
    overflow: hidden;

    .section-title{
        padding-bottom: 10px;
    }

    .soft-line {
        padding: 50px 50px 20px;

        div{
            border-top: 1px solid #9DDBD5;
        }
    }

    .group{
        margin: 0px;

        .awards{
            display: flex;
            padding: 0 1em;

            .award {
                padding: 1em;
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    border: 1px solid #FFFFFF;
                    border-radius: 10px;
                    transition-delay:0s;
                    transition-duration:0.3s;
                    transition-property:all;
                    transition-timing-function:ease;
                    

                    &.astro{
                        min-width: 120px;
                    }

                    &:hover{
                        transform: scale(1.2);
                    }
                }
            }
        }

    }

    @media (max-width: 768px){
        .group{
            .awards{
                flex-direction: column;
                width: 100%;

                .award{
                    img{
                        max-height: 100px;

                        &.astro{
                            width: unset;
                        }
                    }
                }
            }
        }
    }
    
}

.home-story{
    background: linear-gradient(180deg, #FFFFFF 50%, #9DDBD5 50%);

    .team {
        padding: 20px ;
        padding-top: 0px;
        text-align: center;

        img{
            border: 5px solid #9DDBD5;
            border-radius: 15px;
            width: 700px;
        }
    }

    .story{
        font-size: 16px;
        text-align: center;
        padding-bottom: 45px;
        background-color: #9DDBD5;
        padding: 10px;
        margin-bottom: 50px;
    }
}

.home-footer {
    text-align: center;
    background-color: #ECFF00;

    .logo{
        width: 200px;
        border-radius: 10px;
    }

    .zigzag {
        background-image: url('../img/homepage/zigzag-green.png');
        position: absolute;
        height: 20px;
        top: -20px;
        width: 100vw;
        left: 0px;
    }

    .links a {
        margin: 0px 10px;
        text-decoration: none;
        color: #4B4B4B;
        letter-spacing: 1px;
    }

    .social-media{
        a {
            text-decoration: none;
        }

        img{
            margin: 7px 7px 0px;
            width: 35px;
        }
    }
}