// LOGIN & REGISTER PAGE

.login{
	.first{

	}

	.second{
		border: 1px solid blue;
	}
}